import "../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22..%2F..%2Flibs%2Fstyles%2Fsrc%2Flib%2Ftheme.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA22USW5jMQxE9zlFAVoH%2BOIfVVnZsXONhqc4cTw7U6fRd29Qq6b4VbsHqUiRlHg9nd7x5w64v%2F%2FVHw%2B7%2FloRIU5UD%2F%2FjSATpVAYLEeq8DK6J0FQqgxvFM5XBLRHapDK4I0KXl8E9EfpaZfBAhKFSGZwUP6oMXhAh5WXwkgiTTmXwigjTWmXwWvGTyuANER7zMviZCLOkMnhLhHmnMviFCE%2BiMviVCJVMp100eJfxpG97g98yTrGxmewVV9M0s96HjCcyHQw%2B5rzn%2Fdze8pQTjENlQ551fGqVwZfxy1%2FVZFAZfFOcl8HvhL32BxFtuz8JseSLKJr8rafspt%2BKWoN%2B1MnuihVRlywSwzDY%2FKMQ1fnbspqQkjVEU7KW6ErWEUPJes25hAMRXZRERBdmQUQXZ0lEF2illSjhmhAXaEOIC%2FRMiPPcaiVL%2BELUzvOVqF2eO6Jxx9%2BIxgXaE63zPBCt8zwSnfM8EZ270ZnoneeFGNzxK5FcoFuev5LqcPsy64T7kn4qdVf90jY7B532xjnowPu6%2FOhMlA5SKS2jSdRRcXuFiKn0FR19l5k0OhjOtyUkub0dUfdub080bohlyC%2FXWaSxoslipOuyJNqyZLIi6qYu6XqsmbIhuljCsYcgW8J1R15G3pG8jsHdyOuQN%2F%2BryN7%2FIHIY%2BQLkOBbmpJ9oSiU%2BE23JLiPBrzptJbzlz%2FRwe7j7%2Bw8BJQpvhwgAAA%3D%3D%22%7D"
import "../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22..%2F..%2Flibs%2Fstyles%2Fsrc%2Flib%2Ftypography.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA63Uz26CMBzA8TtP0aMe2vQPiOJlrwKjQI1UhgSFhXdfZBrtftUU5rX5pp%2B2lJIqU13yRdG3h1B20A0%2BSZUXTYRCSrfe4JHfgMEgMAIOA98IxD3I4lLtuwi1cb3AeBwqZJwqnS%2B3t%2Baoehkhf12dL0N7pSUurvNSshnHZNPIGh%2Br%2BFPpPEKYEipk%2BUCGs0g6jWSBYSbvNANHNJuDCmFBmaO4myNy29dkhD7dJjdMPct03uXjZa1fUskh7f46bPOvvbXTwZUVFNwN7KeDggRW8umdMUX2%2BgWwk1bQcYvs9f9vBdks8KOUqYrRolQan1TaFJelU1qdl%2BMKjBfQ9OgVHO5ZCLNwBarEqcpgFYSg2sHKF6DSsBIcVDWsuA%2Bq1nIUa1D1lgrOxWznasks58roLRt%2BAIdlYAUJBwAA%22%7D"
import "../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22..%2F..%2Flibs%2Ffooter%2Fsrc%2Flib%2Fdeprecated%2FFooterContent_deprecated.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA61UXW%2BjMBB851fsS6UglYBDCI3zcv%2Fk5GAX3GIb2W6Tuyr%2FveKjxDjQVKd7xJ6dnZ0ds%2F67Myl9T%2BAjAKDcNDX5g6HUnB4CgJI0GN6JXkXR71yKl1wjGbYXDaGUyzKy6gZQTQBHZa0SPoaFh%2BASrPveqOs902qC2kwVSiWZc5t%2Be7ud3h5rVby2Ik%2Bc2goDSpKHblrNaVSo%2Bk1IDHuIIUIOSdaRFKpW2hPKu4lPFbcsMg0pGIajZuS1%2FzAOR%2F7vHJ68HGLYt6eC6JLLBZ%2BL0IHM7Eq6Dj%2F1e%2FBNQK4J%2B%2FmckJqXMuKWCYPBWKLtKNgy0dTEsoHTYNCsYcSuNo%2BAnnW4EDMSzm5kIob8OLRP7pzHO0vwCMe6oqvzMlMxXlYWA3mz6jAbsq8Ftd%2Ff%2Bs%2Fu6OpNNqx%2BHj2%2BBL8Eo5zASnAZXaUlzTns2Nbu83Ze5ZyYl67L5VqFhqobh%2B%2BtFjm79bFanczVrsV3PxGy8YWM5noRQRBD2p8Lch4N2e6as0eZ%2FqfZfqA%2BG1o5ijZZ1ivy9KcQQ%2B6V727Ls3bBHiwfYdfHnniYwh%2B6%2F09OMHTAWHa2URc5DEzSObPR8Its6%2BeTmN4k8cuMCdMWYtgtjL3kz%2BUTu99u4boGAAA%3D%22%7D"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var address = _7a468({defaultClassName:'z6s3dv7 pfiybqv pfiybq1',variantClassNames:{hasLinks:{false:'z6s3dv8'}},defaultVariants:{},compoundVariants:[]});
export var caption = _7a468({defaultClassName:'z6s3dve pfiybqv pfiybq1',variantClassNames:{},defaultVariants:{},compoundVariants:[]});
export var captionWrap = 'z6s3dvd';
export var desktopLogo = 'z6s3dv4';
export var desktopLogoWrap = 'z6s3dv3';
export var footerText = _7a468({defaultClassName:'z6s3dv5 pfiybqr pfiybq1',variantClassNames:{hasLinks:{false:'z6s3dv6'}},defaultVariants:{},compoundVariants:[]});
export var fullLogo = 'z6s3dvc';
export var iconLogoWrap = 'z6s3dv2';
export var innerContainer = 'z6s3dv1';
export var navGroup = 'z6s3dva';
export var navGroups = 'z6s3dv9';
export var navItem = _7a468({defaultClassName:'z6s3dvb pfiybqv pfiybq1',variantClassNames:{},defaultVariants:{},compoundVariants:[]});
export var root = 'z6s3dv0';